<template>
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    stroke="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L6.5 6.5L1 12" />
  </svg>
</template>


import { formatDate } from '@/helpers'
import { computed, defineComponent, PropType, ref } from 'vue'
import Caret from '@/components/vectors/Caret.vue'
import { Announcement, isAnnouncement } from '@/models/Announcement'

export default defineComponent({
  components: { Caret },
  props: {
    announcements: {
      type: Object as PropType<Announcement | { [x: string]: Announcement }>,
      required: true
    }
  },
  setup (props) {
    const announcements = computed(() =>
      isAnnouncement(props.announcements)
        ? []
        : Object.values(props.announcements)
    )
    const activeIndex = ref(0)
    const nextAnnouncement = computed(() =>
      isAnnouncement(props.announcements)
        ? undefined
        : () => {
            activeIndex.value =
              (activeIndex.value + 1) % announcements.value.length
          }
    )

    const announcement = computed(() =>
      isAnnouncement(props.announcements)
        ? props.announcements
        : announcements.value[activeIndex.value]
    )

    return {
      announcement,
      nextAnnouncement,
      formatDate
    }
  }
})

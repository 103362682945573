import { Dashboard } from '@/models/Dashboard'
import httpClient from './httpClient'
import { openGraphElements, metadataElements } from './customElements'
const elements = [
  'announcements',
  'destinations',
  'title',
  'summary',
  'image',
  'alternate_text',
  'body',
  'path',
  'text',
  'date',
  'featured_news_web'
]

const getDashboard = () => {
  const newsElements = [
    'web_content_summary',
    'web_content_body',
    'web_content_image',
    'visible_on'
  ]
  const params = {
    depth: 2,
    elements:
      // eslint-disable-next-line max-len
      `${elements.join(',')},${newsElements.join(',')},${openGraphElements.join(',')},${metadataElements.join(',')}`
  }
  return httpClient.get<Dashboard>('/home_page', {
    params
  })
}

export { getDashboard }

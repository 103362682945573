
import { defineComponent, PropType } from 'vue'

interface routeObject {
  name: string;
}

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    to: {
      type: Object as PropType<routeObject>,
      required: true
    }
  }
})


import { defineComponent } from 'vue'
import FeatureCard from '@/components/FeatureCard.vue'
import Clock from '@/components/vectors/Clock.vue'

export default defineComponent({
  name: 'FeaturedLinks',
  components: {
    FeatureCard,
    Clock
  }
})
